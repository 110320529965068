<template>
  <section style="background-color: #ffff">
    <!--顶层工具条-->
    <toolbar
      :buttonList="buttonList"
      @callFunction="callFunction"
      :buttonListmsg="buttonListmsg"
    ></toolbar>
    <!--角色数据展示-->
    <el-col
      :span="7"
      class="roles"
      style="padding-left: 10px; padding-right: 10px"
    >
      <el-row style="height: 40px">
        <ToolbarButtonGroupMini
          :buttonList="buttonListRole"
          @callFunction="callFunction"
        ></ToolbarButtonGroupMini>
      </el-row>
      <el-divider>角色</el-divider>
      <!--角色列表-->
      <el-table
        :data="roles"
        :show-header="false"
        highlight-current-row
        @current-change="selectCurrentRow"
      >
        <!-- <el-table-column type="index" width="40px"></el-table-column> -->
        <el-table-column prop="Name" show-overflow-tooltip></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleRoleCurrentChange"
        :current-page="currentRolePage"
        :page-size="pageRoleSize"
        layout="prev, pager, next, jumper"
        :page-count="Roletotal"
        :total="Roletotaldata"
      ></el-pagination>
    </el-col>
    <!--用户数据展示-->
    <el-col :span="17" class="post">
      <el-tabs
        tab-position="left"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="用户" name="first">
          <el-row style="height: 40px">
            <ToolbarButtonGroupMini
              :buttonList="buttonListUser"
              @callFunction="callFunction"
            ></ToolbarButtonGroupMini>
          </el-row>
          <el-divider></el-divider>
          <div>
            <el-form
              :inline="true"
              ref="selectForm"
              :model="selectForm"
              class="select_form"
            >
              <el-form-item>
                <el-input
                  v-model="selectForm.userName"
                  placeholder=""
                ></el-input>
              </el-form-item>

              <!-- 这个就是当前页面内，所有的btn列表 -->
              <el-form-item>
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-search"
                  @click="getRoleUser()"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
            <!--用户列表-->
            <el-table :data="peopledata"
                      highlight-current-row
                      @current-change="selectUserRow">
                <el-table-column type="index" width="40px"></el-table-column>
                <el-table-column prop="UserName" label="姓名"></el-table-column>
                <el-table-column prop="Male" label="性别"></el-table-column>
                <el-table-column prop="MobilePhone" label="手机"></el-table-column>
            </el-table>
            <!-- 分页器 -->
            <!-- <el-pagination
              align="center"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="prev, pager, next, jumper"
              :page-count="total"
              :total="totaldata"
            ></el-pagination> -->
            <el-pagination
              align="center"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="prev, pager, next, jumper,total"
              :page-count="total"
              :total="totaldata"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="部门" name="second">
          <el-row style="height: 20px">
            <ToolbarButtonGroupMini
              :buttonList="buttonListDpt"
              @callFunction="callFunction"
            ></ToolbarButtonGroupMini>
          </el-row>
          <el-divider></el-divider>
          <div class="orgtree">
              <el-tree :data="alldepartmentdata"
                       show-checkbox
                       :expand-on-click-node="false"
                       :default-expand-all="true"
                       :check-strictly="isAll"
                       :check-on-click-node="true"
                       highlight-current
                       :props="defaultProps"
                       :indent="30"
                       node-key="id"
                       ref="tree1">
              </el-tree>
              <!--<el-tree
              :data="alldepartmentdata"
              show-checkbox
              :expand-on-click-node="false"
              :default-expand-all="true"
              :check-strictly="false"
              :check-on-click-node="true"
              highlight-current
              :indent="30"
              node-key="id"
              ref="tree1"
              :props="TreeProps"
            ></el-tree>-->

          </div>
        </el-tab-pane>
        <el-tab-pane label="职务" name="third">
          <el-row>
            <ToolbarButtonGroupMini
              :buttonList="buttonListPost"
              @callFunction="callFunction"
            ></ToolbarButtonGroupMini>
          </el-row>
          <el-divider></el-divider>
          <div class="orgtree">
            <el-tree
              :data="allpostdata"
              show-checkbox
              :expand-on-click-node="false"
              :default-expand-all="true"
              :check-strictly="true"
              :check-on-click-node="true"
              highlight-current
              :indent="30"
              node-key="id"
              ref="tree2"
              :props="TreeProps"
            ></el-tree>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-col>
    <!--角色编辑界面-->
    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
      style="width: 50%; left: 25%"
    >
      <el-form
        :model="editForm"
        label-width="80px"
        :rules="editFormRules"
        ref="editForm"
      >
        <el-form-item label="角色名" prop="Name">
          <el-input v-model="editForm.Name" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="editSubmit">提交</el-button>
      </div>
    </el-dialog>

    <!--角色新增界面-->
    <el-dialog
      title="新增"
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :close-on-click-modal="false"
      style="width: 50%; left: 25%"
    >
      <el-form :model="addForm" :rules="addFormRules" ref="addForm">
        <el-form-item label="角色名" prop="Name">
          <el-input v-model="addForm.Name" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog
    title="选择人员"
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :key="userchooseid"
        :all="true"
        :single="false"
        @callback="chooseUserCallBack"
      ></UserChoose>
    </el-dialog>
  </section>
</template>

<script>
import util from "../../../util/date";
import {
  getRoleListPage,
  removeRole,
  editRole,
  addRole,
  getUserListPage,
  removeUser,
  GetRoleUserList,
  AddUserRole,
  DeleteUserRole,
  GetDepartmentTree,
  GetDepartmentRoleList,
  AssignDepartmentRole,
  GetPostTree,
  GetPostRoleList,
  AssignPostRole,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import ToolbarButtonGroupMini from "../../components/ToolbarButtonGroupMini";
import UserChoose from "../../components/UserChoose";
export default {
  components: { Toolbar, ToolbarButtonGroupMini, UserChoose },
  data() {
    return {
      userchooseid: +new Date(),
      activeName: "first",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 10, // 每页的数据条数
      currentRolePage: 1, // 当前页码
      Roletotal: null, // 总条数
      Roletotaldata: null,
      pageRoleSize: 15, // 每页的数据条数
      Rolepage: 1,
      roles: [],
      choosedusers: [],
      buttonListmsg: "请输入角色名称查询",
      buttonList: [],
      buttonListRole: [],
      buttonListUser: [],
      addUserVisible: false,
      buttonListDpt: [],
      alldepartmentdata: [],
      TreeProps: {
        children: "children",
        label: "lable",
        },
        defaultProps: {
            children: 'children',
            label: 'label'
        },
      buttonListPost: [],
      allpostdata: [],
      peopledata: [],
      callbackmsg: [],
      userid: [], //暂存选中角色下所有用户的id
      backrid: null,
      deleteuid: null, //选中需要删除的人员id
      selectForm: {
        userName: "",
      },
      statusList: [
        { name: "激活", value: true },
        { name: "禁用", value: false },
      ],
      total: 0,
      page: 1,
      currentRow: null,
      editFormVisible: false, //编辑界面是否显示
      editFormRules: {
        Name: [{ required: true, message: "请输入角色名", trigger: "blur" }],
        Enabled: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
      //编辑界面数据
      editForm: {
        Id: 0,
        Name: null,
        NormalizedName: null,
      },
      addFormVisible: false, //新增界面是否显示
      addFormRules: {
        Name: [{ required: true, message: "请输入角色名", trigger: "blur" }],
        Enabled: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
      //新增界面数据
      addForm: {
        Name: null,
        NormalizedName: null,
      },
      //设置卡片背景颜色
      elCard: {
        background: "",
      },
    };
  },
  methods: {
    //toolbar查询
    callFunction(item) {
      this.filters = {
        name: item.search,
      };
      this[item.Func].apply(this, item);
    },
    handleClick(tab, event) {
      if (this.currentRow != null) {
        let row = this.currentRow;
        if (tab.name == "first") {
          this.getRoleUser(row);
        } else if (tab.name == "second") {
          this.getRoleDepartment(row);
        } else if (tab.name == "third") {
          this.getRolePost(row);
        }
      }
    },
    //角色列表点击
    selectCurrentRow(val) {
      console.log(1312132312123);
      this.currentPage = 1;
      this.page = 1;
      this.pageSize = 20;
      if (val != null) {
        this.selectForm.userName = "";
        this.currentRow = val;
        let row = this.currentRow;
        this.backrid = row.Id;
        if (this.activeName == "first") {
          this.getRoleUser(row);
        } else if (this.activeName == "second") {
          this.getRoleDepartment(row);
        } else if (this.activeName == "third") {
          this.getRolePost(row);
        }
      }
    },
    //用户列表点击
    selectUserRow(val) {
      if (val != null) {
        this.deleteuid = val.UserId;
      }
    },
    formatCreateTime: function (row, colume) {
      return !row.LockoutEnd || row.LockoutEnd == ""
        ? ""
        : util.formatDate.format(new Date(row.LockoutEnd), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      console.log(this.currentPage);
      this.page = val;
      this.getRoleUser();
    },
    handleRoleCurrentChange(val) {
      this.Rolepage = val;
      this.getRoles();
    },
    //获取用户列表
    getRoles() {
      let para = {
        name: this.filters == undefined ? null : this.filters.name,
        page: this.Rolepage,
        size: this.pageRoleSize,
      };
      getRoleListPage(para).then((res) => {
        this.Roletotal = res.data.response.pageCount;
        this.Roletotaldata = res.data.response.dataCount;
        this.roles = res.data.response.data;
      });
    },

    //删除
    handleRoleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });

        return;
      }
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          let para = { id: row.Id };
          removeRole(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.peopledata.splice(0, this.peopledata.length);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }

            this.getRoles();
          });
        })
        .catch(() => {});
    },
    //显示编辑界面
    handleRoleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });

        return;
      } else {
        this.editFormVisible = true;
        this.editForm.Id = row.Id;
        this.editForm = Object.assign({}, row);
      }
    },
    //显示新增界面
    handleRoleAdd() {
      this.addFormVisible = true;
      this.addForm = {
        Name: "",
        NormalizedName: "",
      };
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            //NProgress.start();
            let para = Object.assign({}, this.editForm);
            para.NormalizedName = para.Name.toUpperCase();
            editRole(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["editForm"].resetFields();
                this.editFormVisible = false;
                this.getRoles();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //新增
    addSubmit: function () {
      let _this = this;
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            //NProgress.start();
            let para = Object.assign({}, this.addForm);
            para.NormalizedName = para.Name.toUpperCase();
            addRole(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["addForm"].resetFields();
                this.addFormVisible = false;
                this.getRoles();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //获取用户列表
    getUsers() {
      let para = {
        page: this.page,
        size: this.pageSize,
      };
      getUserListPage(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.peopledata = res.data.response.data;
      });
    },
    //根据点击的角色获取相关用户
    getRoleUser(row) {
      this.peopledata = [];
      // this.total = 0;
      // this.totaldata = 0;
      // let rolerow = row;
      let para = {
        roleId: this.backrid,
        page: this.page,
        size: this.pageSize,
        name: this.selectForm.userName,
      };
      GetRoleUserList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.peopledata = res.data.response.data;
        for (var i = 0; i < this.peopledata.length; i++) {
          this.userid.push(this.peopledata[i].Id);
        }
      });
    },
    //选择人员按钮点击
    chooseUser() {
      if (this.currentRow != null) {
        this.addUserVisible = true;
      }
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack(data) {
      var newdata = data.map(function (item) {
        return item["Id"];
      });
      let c = newdata.filter((i) => !this.userid.includes(i)); //新增
      let callbackpara = {
        roleId: this.backrid,
      };
      let para = {
        RoleId: this.backrid,
        UserKeys: [],
      };
      if (c.length != 0) {
        var addArray = [];
        for (var i = 0; i < c.length; i++) {
          addArray.push(c[i]);
        }
        para.UserKeys = addArray;
        AddUserRole(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            GetRoleUserList(callbackpara).then((res) => {
              this.peopledata = res.data.response.data;
            });
            this.addUserVisible = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
    //根据角色删除相关用户
    deleteRoleUser() {
      let para = {
        uid: this.deleteuid,
        rid: this.backrid,
      };
      DeleteUserRole(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          GetRoleUserList({ roleId: this.backrid }).then((res) => {
            this.peopledata = res.data.response.data;
          });
          this.deleteuid = null;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //获取角色关联部门
    getRoleDepartment(row) {
      this.$refs.tree1.setCheckedKeys([]);
      let para = {
        roleId: row.Id,
      };
      GetDepartmentRoleList(para).then((res) => {
        var newdata = res.data.response;
        // var newdata = res.data.response.map(function (item) {
        //   return item['DepartmentId'];
        // });
        // console.log(newdata)
        this.$refs.tree1.setCheckedKeys(newdata);
      });
    },
    //角色分配部门权限
    saveRoleDpt() {
      if (this.currentRow != null) {
        let row = this.currentRow;
        let dptids = this.$refs.tree1.getCheckedKeys();
        let para = {
          RoleId: row.Id,
          DepartmentKeys: dptids,
        };
        AssignDepartmentRole(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
    //获取角色关联职务
    getRolePost(row) {
      this.$refs.tree2.setCheckedKeys([]);
      let para = {
        roleId: row.Id,
      };
      GetPostRoleList(para).then((res) => {
        var newdata = res.data.response;
        // var newdata = res.data.response.map(function (item) {
        //   return item['PostId'];
        // });
        this.$refs.tree2.setCheckedKeys(newdata);
      });
    },
    //角色分配职务权限
    saveRolePost() {
      if (this.currentRow != null) {
        let row = this.currentRow;
        let postids = this.$refs.tree2.getCheckedKeys(true);
        let para = {
          RoleId: row.Id,
          PostKeys: postids,
        };
        AssignPostRole(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
  },
  watch: {
    addUserVisible(val) {
      this.userchooseid = +new Date();
    },
  },
  mounted() {
    this.getRoles();

      GetDepartmentTree().then((res) => {
          console.log(res)
          if (res.data.code == 200) {
                this.alldepartmentdata = res.data.data.dept;
              console.log(res.data.data);
      }
    });
    GetPostTree().then((res) => {
      if (res.data.success) {
        this.allpostdata = res.data.response.children;
      }
    });

    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    // //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    var allButtons = getButtonList(this.$route.path, routers, buttons);
    allButtons.forEach((element) => {
      if (
        element.Func &&
        (element.Func.toLowerCase() == "handleRoleAdd".toLowerCase() ||
          element.Func.toLowerCase() == "handleRoleEdit".toLowerCase() ||
          element.Func.toLowerCase() == "handleRoleDel".toLowerCase())
      ) {
        this.buttonListRole.push(element);
      } else if (
        element.Func &&
        (element.Func.toLowerCase() == "deleteRoleUser".toLowerCase() ||
          element.Func.toLowerCase() == "chooseUser".toLowerCase())
      ) {
        this.buttonListUser.push(element);
      } else if (
        element.Func &&
        element.Func.toLowerCase() == "saveRoleDpt".toLowerCase()
      ) {
        this.buttonListDpt.push(element);
      } else if (
        element.Func &&
        element.Func.toLowerCase() == "saveRolePost".toLowerCase()
      ) {
        this.buttonListPost.push(element);
      } else {
        this.buttonList.push(element);
      }
    });
  },
};
</script>

<style scoped>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}

.el-col
  >>> .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #fabe64;
}

.el-col >>> .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.el-col >>> .el-icon-caret-right:before {
  content: url("../../assets/add.png") !important;
  font-size: 16px;
}
.el-col >>> .expanded:before {
  content: url("../../assets/cancel.png") !important;
  font-size: 16px;
}
.el-col >>> .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: url("../../assets/blank.png") !important;
  font-size: 16px;
}
.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}
.orgtree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 280px);
  /*width:200px;*/
  border: 0px solid blue;
}
</style>
